import React from "react"
import { graphql, StaticQuery } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Layout = ({ data, style }) => {
  return (
    <img
      srcSet={data.fluid.srcSet}
      style={style}
      alt={data.fluid.originalName}
    />
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        images: allImageSharp {
          nodes {
            fluid(maxWidth: 500, quality: 100) {
              originalName
              srcSet
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.nodes.find(
        img => img.fluid.originalName === props.image
      )
      return <Layout data={image} {...props} />
    }}
  />
)

import React from "react"
import { useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Background from "../components/background"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          about
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" description="Inspiring New Heights" />
      <Background
        image="background.jpg"
        padding="40vh"
        className="landing bg-dark"
        // mode="scroll"
        layers={[
          {
            angle: "180deg",
            colors: [
              { position: "0%", color: "rgba(0,0,0,0)" },
              { position: "100%", color: "#000" },
            ],
          },
        ]}
      >
        <div className="container">
          <h1>
            ASPIRE THE EMPIRE
            <br />
            帝国を熱望
          </h1>
          <sub>INSPIRING NEW HEIGHTS</sub>
        </div>
      </Background>

      <div className="content text-center">
        <div className="container">
          <h3>About</h3>
          <p>{data.site.siteMetadata.about}</p>
        </div>
      </div>

      <Background
        image="DSC00498.jpg"
        padding="20vh"
        className="landing text-center"
      >
        <div className="container">
          <h1>RISE WITH US</h1>
          <p>
            We love being able to create with others so lets collaborate
            together.
          </p>
        </div>
      </Background>

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col">
              <Image image="IMG_4081.jpg" style={{ width: `100%` }} />
            </div>
            <div className="col">
              <Image image="IMG_4431.jpg" style={{ width: `100%` }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
